.navigation {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 55px;

  a {
    color: #5578AA;
  }

  
  .vc-logo {
    width: 450px;
    
    @media (max-width: 767px) {
      width: 100%; 
    }
  }
}