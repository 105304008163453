.project-section {
    margin-bottom: 45px;
    border-bottom: 2px solid #f09f9b; 

    &:last-of-type {
        border-bottom: none;
    }
}

.project-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
}

.project {  
    @media (min-width: 767px) {
        display: flex;
    } 
    .project-image {
        margin-bottom: 30px;
        max-width: 500px;
        
        img {
            width: 100%;
        }
    }

    .project-description {
        margin-left: 30px;
    }
}

.project-buttons {
    display: flex;
    margin-top: 22px;
    margin-bottom: 50px;
}

.button {
    border: 2px solid #5578AB;
    border-radius: 6px;
    background-color: #5578AA;
    color: white;
    padding: 5px;
    display: block;
    width: 110px;
    text-align: center;
    text-decoration: none;
    margin-right: 12px;

    &:hover, &:active {
        background-color: white;
        color: #5578AA;
        text-decoration: none;
    }
}