.about-me-content {
    display: flex;
    flex-direction: column;

    @media (min-width: 767px) {
        flex-direction: row;
    }

    .about-me-img-container {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      .about-me-img {
        width: 100%;
        max-width: 300px;
        align-self: center;
        border-radius: 40px; 

        @media (min-width: 767px) {
          width: 300px;
        }
      }
    }
    .about-me-bio {
        @media (min-width: 767px) {
            margin-left: 50px;
        }

      .interests {
        text-align: center;
        padding: 30px;
        border: 3px solid #F09F9B;
        margin: 30px 0px 30px 0px;
      }
    }

    .fab {
      font-size: 25px;
      margin-right: 10px;

      &.fa-linkedin-in {
        color: #0072b1;
      }

      &.fa-github {
        color: black;
      }
  
      &.fa-twitter {
        color:  #1DA1F2
      }
    }

    .visually-hidden {
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;

      &:focus {
        clip: auto;
        height: auto;
        overflow: auto;
        position: absolute;
        width: auto;
      }
    }

  }
  